import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  ChangeDetectorRef,
  OnDestroy,
  SimpleChanges,
  OnInit, OnChanges
} from '@angular/core';

import {
  interval,
  Observable,
  Subscription,
} from 'rxjs';

import {
  filter,
} from 'rxjs/operators';

import {
  Select,
  Store,
} from '@ngxs/store';

import {
  Admin,
  CoreNote,
  Problem,
  SearchCriteria,
  SearchFilter,
  ConstraintViolationProblem,
  SearchOperator,
  UserAccountVerificationStatus,
  IdvChecks,
  IdvVerificationReports,
  IdvDocuments,
  IdvDocumentsDownload,
  IdvVerificationReportsDocuments,
} from '@michel.freiha/ng-sdk';

import {
  Dialog,
} from '@nymos/theme';

import {
  ProfileState,
} from '@nymos/auth';

import {
  AddNoteFromPage,
  LoadUserNotesFromPage,
  Note,
  NoteConfirmComponent,
  NotesState,
} from '@nymos/notes';

import {
  Account,
  AccountsUsersState,
  Card,
  CardActivity,
  ChannelStatus,
  Texts,
  Wallet,
  WalletActivity,
} from '@nymos/accounts/core';

import {
  AccountsState,
  BlockAccountFromUserDetailsPage,
  GetAccountFromUserDetailsPage,
  RefreshAccountFromUserDetailsPage,
  RejectAccountFromUserDetailsPage,
  ResetPasscodeFromUserDetailsPage,
  UnblockAccountFromUserDetailsPage,
  VerifyAccountFromUserDetailsPage,
} from '@nymos/accounts/core';

import {
  CardsState,
} from '../../../core/store/cards/cards.state';

import {
  ActivateFromUserDetailsPage,
  LoadCardsFromUserDetailsPage,
  LoadCardFromUserDetailsPage,
  RefreshCardsFromUserDetailsPage,
  ResumeFromUserDetailsPage,
  SuspendFromUserDetailsPage,
  TerminateFromUserDetailsPage,
  GetInternationalTranscationStatusFromUserDetailsPage,
  AllowInternationalTranscationFromUserDetailsPage,

} from '../../../core/store/cards/cards.actions';

import {
  WalletsState,
} from '../../../core/store/wallets/wallets.state';

import {
  AccountsProvincesNewState,
} from '../../../core/store/accounts-provinces-new/accounts-provinces-new.state';

import {
  LoadWalletsFromUserDetailsPage,
  RefreshWalletsFromUserDetailsPage,
  CreateIqdWallet
} from '../../../core/store/wallets/wallets.actions';

import {
  Navigate,
} from '@ngxs/router-plugin';

import {
  CardActivitiesState,
} from '../../../core/store/card-activities/card-activities.state';

import {
  LoadCardActivitiesFromUserDetailsPage,
  RefreshCardActivitiesFromUserDetailsPageNew,
} from '../../../core/store/card-activities/card-activities.actions';

import {
  WalletActivitiesState,
} from '../../../core/store/wallet-activities/wallet-activities.state';

import {
  ApproveActivityFromUserDetailsPage,
  CancelActivityFromUserDetailsPage,
  LoadWalletActivitiesFromUserDetailsPage,
  RefreshWalletActivitiesFromUserDetailsPage,
  RejectActivityFromUserDetailsPage,
  RetryActivityFromUserDetailsPage,
  TransferBetweenWalletFromUserDetailsPage,
  DryRunTransferBetweenWalletFromUserDetailsPage
} from '../../../core/store/wallet-activities/wallet-activities.actions';

import {
  CardHandler,
} from '../../../services/card-handler.service';

import {
  ChannelHandler,
} from '../../../services/channel-handler.service';

import {
  WalletActivityHandler,
} from '../../../services/wallet-activity-handler.service';

import {
  CardDetailsComponent,
} from '../card-details/card-details.component';

import {
  CardSelectionComponent,
} from '../card-selection/card-selection.component';

import {
  UserCardActivityComponent,
} from '../user-card-activity/user-card-activity.component';

import {
  UserWalletActivityComponent,
} from '../user-wallet-activity/user-wallet-activity.component';

import {
  WalletTopupConfirmComponent,
} from '../wallet-topup-confirm/wallet-topup-confirm.component';

import {
  WalletTopupRefundComponent,
} from '../wallet-topup-refund/wallet-topup-refund.component';

import {
  CardToCardTransferComponent,
} from '../card-to-card-transfer/card-to-card-transfer.component';
import { IssueCardComponent } from '../issue-card/issue-card.component';
import { GetIdvReport, GetUserIdvCheck, GetUserIdvDocumentStatus, DownloadUserIdvDocument } from '../../../core/store/accounts-users/accounts-users.actions';



@Component({
  selector: 'nym-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent implements OnInit, OnDestroy, OnChanges {

  private _subscription: Subscription = new Subscription();
  private _problem: Problem;
  private _account: Account;
  private _wallet: Wallet;
  public wallect_info :any;
  public refundPermission$:boolean;
  public currencyInfo$: string;
  public isReport :any;

  @HostBinding('class.nym-user-details')
  protected get classes(): boolean { return true; }

  protected account$: Observable<Account>;

  protected provincesNew$$: Observable<string[]>;

  @Select(AccountsUsersState.loading)
  public accountLoading$: Observable<boolean>;

  @Select(AccountsUsersState.problem)
  public problem$: Observable<Problem>;

  @Select(CardsState.cards)
  public cards$: Observable<Card[]>;


  public card$: Observable<Card>;

  @Select(CardsState.loading)
  public cardsLoading$: Observable<boolean>;

  @Select(CardActivitiesState.userActivities)
  public cardActivities$: Observable<CardActivity[]>;

  @Select(CardActivitiesState.loading)
  public cardActivitiesLoading$: Observable<boolean>;

  @Select(WalletsState.mainWallet)
  public wallet$: Observable<Wallet>;

  // @Select(WalletsState.createWalletIqd)
  // public createWalletIqd$: Observable<Wallet>;

  @Select(WalletsState.loading)
  public walletLoading$: Observable<boolean>;

  @Select(WalletsState.problem)
  public walletProblem$: Observable<Problem>;

  @Select(WalletActivitiesState.userActivities)
  public walletActivities$: Observable<WalletActivity[]>;

  @Select(WalletActivitiesState.loading)
  public walletActivitiesLoading$: Observable<boolean>;

  protected userNotes$: Observable<Note[]>;
  protected cardNotes$: Observable<Note[]>;
  protected get problem(): ConstraintViolationProblem { return this._problem; }
  protected accountId: string;
  protected idvChecks: IdvChecks[];
  protected idvReportResult:IdvVerificationReports
  protected idvDocument :IdvDocuments;
  protected downloadUserIdvDocumentResult: IdvDocumentsDownload[] = [];
  protected isAllowInternationTrans:boolean;
 

  constructor(
    private _store: Store,
    private _dialog: Dialog,
    private _cardHandler: CardHandler,
    private _cd: ChangeDetectorRef,
    private _channelHandler: ChannelHandler,
    private _walletActivityHandler: WalletActivityHandler,
  ) {
    const router = this._store.selectSnapshot((state) => state.router.state);

    this.accountId = router.params.id;

    const criteria = new SearchCriteria({
      filters: [new SearchFilter({ field: 'account_id', operator: SearchOperator.AnyOf, values: [this.accountId] })],
    });

    this._store.dispatch([
      new GetAccountFromUserDetailsPage(this.accountId),
      new LoadWalletsFromUserDetailsPage(this.accountId),
      new LoadWalletActivitiesFromUserDetailsPage(this.accountId),
      new LoadCardsFromUserDetailsPage(this.accountId),
      new LoadUserNotesFromPage(criteria),
    ]);

    this._subscription.add(interval(10000).subscribe(() => {
      this._store.dispatch([
        new RefreshAccountFromUserDetailsPage(this.accountId),
        new RefreshWalletsFromUserDetailsPage(this.accountId),
        // new RefreshWalletActivitiesFromUserDetailsPage(this.accountId),
        new RefreshCardsFromUserDetailsPage(this.accountId),
        //new RefreshCardActivitiesFromUserDetailsPageNew(this.accountId),
      ]);
    }));
    
    this.account$ = this._store.select(AccountsUsersState.account(this.accountId));
    this.userNotes$ = this._store.select(NotesState.userNotes(this.accountId));
    this.provincesNew$$ = this._store.select(AccountsProvincesNewState.newprovinces);
  }

   

  public ngOnInit(): void {
      this._store.selectSnapshot((state) =>{
       if(state.profile.scopes.indexOf('p:wallettrans:debit')>-1){
         this.refundPermission$ = true;
       }else{
          this.refundPermission$=false;
       }
       if(state.profile.scopes.indexOf('p:users:cardtransactions')>-1){
         this._store.dispatch(
            new LoadCardActivitiesFromUserDetailsPage(this.accountId)
          );
       }
     })
     this._subscription.add(this.account$.subscribe((account) => this._account = account));
     this._subscription.add(this.wallet$.subscribe((wallet) => {
      this._wallet = wallet
       if(this._account.verificationStatus === UserAccountVerificationStatus.Kyc2Approved && this._wallet){
         this.createWalletIqd()
        } 
       }));
     this._subscription.add(this.problem$.pipe(filter((p) => !!p)).subscribe((p) => {
      this._problem = p;
      this._cd.markForCheck();
    }));
     this._store.dispatch([new GetUserIdvDocumentStatus(this.accountId)]).subscribe((res)=>{
       if(res[0].users.idvDocument && res[0].users.idvDocument.length>0){
        let arr = res[0].users.idvDocument;
             let idvDoc = arr.map(function(obj) { return obj.uploadedDocument; });
             idvDoc = idvDoc.filter(function(v,i) { return idvDoc.indexOf(v) == i; });
             this.idvDocument = idvDoc;
             
             this.getIdvCheck()
        }
     })
     if(this._account.channel.channel !=='incomplete'){
       this.getCardholderInternationalTransactionStatus();
     }

  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.account$ = this._store.select(AccountsUsersState.account(this.accountId));
    this.userNotes$ = this._store.select(NotesState.userNotes(this.accountId));
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected viewCard(card: Card): void {
    this._dialog.openAsFullwidth(CardDetailsComponent, { data: card });
  }

  protected viewCards(): void {
    this._dialog.openAsFullwidth(CardSelectionComponent, { data: this._account });
  }

  protected viewCardActivities(): void {
    this._dialog.openAsFullwidth(UserCardActivityComponent, { data: this._account });
  }

  protected viewWalletActivities(): void {
    const account = this._account;
    this._dialog.openAsFullwidth(UserWalletActivityComponent, { data: { account } });
  }

  protected createWalletIqd(): void {
      if(!this._wallet.IqdBallance){
        this._subscription.add(this._store.dispatch(new CreateIqdWallet(this.accountId)).subscribe((res)=>{
          setTimeout(()=>{
            this._store.dispatch([new RefreshWalletsFromUserDetailsPage(this.accountId)])
          },10)
        }));
      } 
  }

  protected getIdvCheck(): void {
    let isAgent = false;
    this._store.dispatch([new GetUserIdvCheck(this.accountId,isAgent)]).subscribe((res)=>{
      this.idvChecks = res[0].users.idvCheck;
      this._cd.markForCheck();
    })
}

protected downloadUserIdvDocument(documentIds: IdvVerificationReportsDocuments[]): void {
  documentIds.forEach(({id}) => {
    this._store.dispatch([new DownloadUserIdvDocument(this.accountId, id)]).subscribe((res)=>{
      this.downloadUserIdvDocumentResult.push(res[0].users.downloadUserIdvDocumentResult);
      this._cd.markForCheck();
    })
  })
}

  protected viewTopup(): void {
    const text = Texts.TopupDialog.TopupTitle(this._account.profile.fullName);
    const account = this._account;
    this._dialog.openAsModal(WalletTopupConfirmComponent, {
      data: {
        text,
        account,
        cancel: { label: 'Cancel' },
        confirm: { label: 'Submit' },
      },
    });
  }

     

   protected viewTopupRefund(): void {
      const text = Texts.TopupDialog.RefundTitle(this._account.profile.fullName);
      let account = this._account;
      this._dialog.openAsModal(WalletTopupRefundComponent, {
      width: '400px',
      data: {
        text,
        account,
        cancel: { label: 'Cancel' },
        confirm:{ label: 'Refund' },
      },
    });
  }

  protected viewIssueCard(): void {
    this._dialog.openAsModal(IssueCardComponent, {
      width: '400px',
      data: {
        cards:this.cards$,
        wallet:this._wallet,
        text: 'Issue Card',
        cancel: { label: 'Cancel' },
        confirm:{ label: 'Issue' },
      },
    });
  }

  protected loadWallet(): void {
    this._store.dispatch(new LoadWalletsFromUserDetailsPage(this.accountId));
  }

  protected viewAttachments(event: any): void {
    const urls = event.attachments;
    const index = event.index;
    this._dialog.openPreview({ urls, index });
  }

  protected getReportId(event:any){
     let reportId =  event;
     this.isReport = event;
     this._store.dispatch([new GetIdvReport(reportId,this.accountId)]).subscribe((res)=>{
      this.idvReportResult = res[0].users.idvReportResult
      this._cd.markForCheck();
      this.downloadUserIdvDocumentResult = [];
      this.downloadUserIdvDocument(this.idvReportResult.documents);
    })
  }

  protected addNote(): any {
    const ref = this._dialog.openConfirm(NoteConfirmComponent, {
      text: Texts.Notes.AddNew,
      confirm: { label: 'Submit' },
      cancel: { label: 'Cancel' },
    });

    ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
      return this._store.dispatch(new AddNoteFromPage(this._account.id, note));
    });

  }


protected cardToCardTransfer(card:any): void {
      this._account['cardId'] = card.id;
      const text = Texts.TopupDialog.TransferCardtoCardTitle('Transfer Money');
      let account = this._account;
      const ref = this._dialog.openAsModal(CardToCardTransferComponent, {
       width: '400px',
       data: {
        card: card,
        text,
        account,
        cancel: { label: 'Cancel' },
        confirm:{ label: 'Transfer' },
      },
    });
  }

  protected resetPasscode$$(): void {
    const ref = this._dialog.openConfirm(NoteConfirmComponent, {
      text: Texts.UserAction.ResetPasscode(this._account.profile.fullName, this._account.profile.mobile),
      confirm: { label: 'Reset' },
      cancel: { label: 'Cancel' },
    });

    ref.afterClosed().pipe(filter((r) => !!r)).subscribe((note: CoreNote) => {
      this._store.dispatch(new ResetPasscodeFromUserDetailsPage(this._account.id, note));
    });
  }

  protected editUser$$(account: Account): void {
    if (!account.id)
      return;

    this._store.dispatch(new Navigate([`accounts/${account.id}/${account.type}/edit`]));
  }

  protected viewReports$$(account: Account): void {
    if (!account.id)
      return;

    this._store.dispatch(new Navigate([`accounts/${account.id}/${account.type}/report`]));
  }



public updateIsAllowInternationalTrns$(status :boolean){
   this._store.dispatch(new AllowInternationalTranscationFromUserDetailsPage(this._account.id, this._account.id, status));
   this.isAllowInternationTrans = status;
   this._cd.markForCheck();
   this.getCardholderInternationalTransactionStatus()
   
  }

protected getCardholderInternationalTransactionStatus(){
  setTimeout(()=>{
    this._store.dispatch(new GetInternationalTranscationStatusFromUserDetailsPage(this._account.id, this._account.id)).subscribe((res)=>{
      this.isAllowInternationTrans = res.cards.isAlllowedInternationalTransaction;
      this._cd.markForCheck();
    })
   }, 400);
    
  }  


  protected changeChannel(channel: ChannelStatus): any {
    // tslint:disable-next-line: switch-default
    switch (channel) {
      case 'rejected':
        return this._channelHandler.reject(this._account, RejectAccountFromUserDetailsPage);

      case 'verified':
            this.createWalletIqd()
            return this._channelHandler.verify(this._account,this._wallet, VerifyAccountFromUserDetailsPage);

      case 'blocked':
        return  this._channelHandler.block(this._account, BlockAccountFromUserDetailsPage);

      case 'unblocked' as ChannelStatus:
        return this._channelHandler.unblock(this._account, UnblockAccountFromUserDetailsPage);
    }
  }

  protected manageCard({ card, action }: any): any {
    // tslint:disable-next-line: switch-default
    switch (action) {
      case 'activate':
        return this._cardHandler.activate(card, ActivateFromUserDetailsPage);

      case 'resume':
        return this._cardHandler.resume(card, ResumeFromUserDetailsPage);

      case 'suspend':
        return  this._cardHandler.suspend(card, SuspendFromUserDetailsPage)

      case 'terminate':   
       return this._cardHandler.terminate(card,this._wallet,DryRunTransferBetweenWalletFromUserDetailsPage,TransferBetweenWalletFromUserDetailsPage, TerminateFromUserDetailsPage);

       case 'refund':   
       return this._cardHandler.refund(card,this._wallet,DryRunTransferBetweenWalletFromUserDetailsPage,TransferBetweenWalletFromUserDetailsPage);
      
       case 'transfer':   
        this.cardToCardTransfer(card)
       
    }
  }

   
  protected manageWalletActivity({ activity, action }: any): any {
    // tslint:disable-next-line: switch-default
    switch (action) {
      case 'approve':
        return this._walletActivityHandler.approve(this._account, activity, ApproveActivityFromUserDetailsPage);

      case 'reject':
        return this._walletActivityHandler.reject(this._account, activity, RejectActivityFromUserDetailsPage);

      case 'cancel':
        return this._walletActivityHandler.cancel(this._account, activity, CancelActivityFromUserDetailsPage);

      case 'retry':
        return this._walletActivityHandler.retry(this._account, activity, RetryActivityFromUserDetailsPage);
    }
  }
}
